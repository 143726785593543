import React, { Component } from 'react';
import { Button, InputGroup, InputGroupAddon, Input, FormGroup, Label, Col, Row } from 'reactstrap';
import { ukAddresses } from "../../queries/Queries";
import { Query } from "react-apollo";
import Loading from "../Loading";
import i18n from "../../views/Pages/Login/i18n";

class FindAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quoteType: this.props.quoteType,
            value: '',
            noAddress: false,
            newAddress: false
        }
        this.findAddress = this.findAddress.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    findAddress(targetValue) {
        let value  = targetValue || document.getElementsByName('postalCode')[0].value
        if (value) {
            // replace all spaces and convert to uppercase
            value = value.replace(/\s+/g, "").toUpperCase();
            // Check if this.props.address is already set and the postalCode is the different from the one in the input and if is it, reset the address
            if (this.props.address.postalCode && this.props.address.postalCode.replace(/\s+/g, "").toUpperCase() !== value) {
                this.resetAddress();
            }
            this.setState({ value: value });
        }
    }
    
    onChange(e) {
        if (document.getElementsByName('createquote')[0]) document.getElementsByName('createquote')[0].checkValidity();
        this.props.onChange({ ...this.props.address, [e.target.name]: e.target.value }, false, true);
    }
    
    resetAddress(e) {
        this.props.onChange({});
        // Set value to empty string to prevent query from running
        this.setState({ value: '', options: [] });
    }

    render() {
        const houseNumber = <FormGroup>
            <Label for="houseNumber">{i18n.t('address.houseNumber')}</Label>
            <Input type="text" name="houseNumber" id="houseNumber" placeholder="Enter Building Number"
                value={this.props.address.houseNumber} onChange={(e) => this.onChange(e)} maxlength="4" />
        </FormGroup>;
        const block = <FormGroup>
            <Label for="block">{i18n.t('address.block')}</Label>
            <Input type="text" name="block" id="block" placeholder="Enter Building Name"
                value={this.props.address.block} onChange={(e) => this.onChange(e)} maxlength="50" />
        </FormGroup>;
        const dependentStreet = <FormGroup>
            <Label for="dependentStreet">{i18n.t('address.dependentStreet')}</Label>
            <Input type="text" name="dependentStreet" id="dependentStreet"
                placeholder="Enter Address Line 2" value={this.props.address.dependentStreet}
                onChange={(e) => this.onChange(e)} maxlength="40" />
        </FormGroup>;
        const street = <FormGroup>
            <Label for="street">{i18n.t('address.street')}</Label>
            <Input type="text" name="street" id="street" placeholder="Enter Address Line 1"
                value={this.props.address.street} onChange={(e) => this.onChange(e)} required maxlength="40" />
        </FormGroup>;
        const city = <FormGroup>
            <Label for="city">{i18n.t('address.city')}</Label>
            <Input type="text" name="city" id="city" placeholder="Enter City/Town"
                value={this.props.address.city} onChange={e => this.onChange(e)} required maxlength="40" />
        </FormGroup>;
        const county = <FormGroup>
            <Label for="county">{i18n.t('address.county')}</Label>
            <Input type="text" name="county" id="county" placeholder="Enter County"
                value={this.props.address.county} onChange={e => this.onChange(e)} maxlength="40" />
        </FormGroup>;
        const postalCode = <FormGroup>
            <Label for="postalCode">{i18n.t('address.postalCode')}</Label>
            <Input type="text" name="postalCode" className="postcodeInput" id="postCode" placeholder="Enter Post Code"
                value={this.props.address.postalCode} onChange={e => this.onChange(e)} required
                pattern={"^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? [0-9][A-Za-z]{2}|[Gg][Ii][Rr] 0[Aa]{2})$"} />
        </FormGroup>;
        return (
          <div className="findAddress">
            <p>
              <Input
                type="radio"
                name={"newAddress_" + this.props.name}
                id={"existingAddress_" + this.props.name}
                value={!this.state.newAddress}
                checked={!this.state.newAddress}
                onChange={(e) => this.setState({ newAddress: false })}
                disabled={this.props.disabled}
              />
              <label
                htmlFor={"existingAddress_" + this.props.name}
                className="css-label"
              >
                <i className="icon ion-android-radio-button-off" />
                <i className="icon ion-android-radio-button-on" />
                &nbsp; &nbsp;Search and select an existing address:
              </label>
            </p>
            {!this.state.newAddress && (
              <div>
                <InputGroup>
                  <Input
                    autoFocus
                    name="postalCode"
                    className="postcodeInput"
                    defaultValue={this.props.address.postalCode}
                    onPaste={(e) => {
                      const pastedData = e.clipboardData.getData("text");
                      this.findAddress(pastedData);
                    }}
                    placeholder="Enter Postcode or Meter point"
                    disabled={this.props.disabled}
                  />
                  <InputGroupAddon>
                    <Button
                      color="primary"
                      onClick={() => this.findAddress()}
                      disabled={this.props.disabled}
                    >
                      Search
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                <FormGroup>
                  {/* Skip the query in first render and when the input is empty between radio button changes */}
                  <Query
                    skip={this.state.value === ""}
                    query={ukAddresses}
                    variables={{ searchValue: this.state.value }}
                  >
                    {({ loading, error, data }) => {
                      if (loading) return <Loading />;

                      let options =
                        data && data.uk_addresses
                          ? data.uk_addresses
                              .map((address) => {
                                return {
                                  ...address,
                                  mprn:
                                    address.meterData.length > 0
                                      ? address.meterData[0].mprn
                                      : null,
                                  gas_meter_serial_number:
                                    address.meterData.length > 0
                                      ? address.meterData[0]
                                          .gas_meter_serial_number
                                      : null,
                                  foundByMprn:
                                    address.meterData.length > 0 &&
                                    address.meterData[0].mprn ===
                                      this.state.value,
                                };
                              })
                              .filter((e) => {
                                let hasExpectedMprnProps = e.meterData.length > 0 && e.meterData[0].mprn && this.state.value !== e.mpan;
                                let hasExpectedMpanProps = e.mpan !== null && e.mpan !== undefined && this.state.value !== e.mprn;

                                if (e.postalCode) {
                                  // If create condition for dual, add it in the first position
                                  if (this.state.quoteType === "gas" && hasExpectedMprnProps) return e.meterData[0].mprn;
                                  else if (this.state.quoteType === "electricity" && hasExpectedMpanProps) return e.mpan;
                                }
                              })
                          : [];

                      return (
                        <Input
                          type="select"
                          name={this.props.name}
                          id="address"
                          value={
                            this.props.address.postalCode &&
                            this.props.address.postalCode ===
                              this.state.postalCode
                              ? JSON.stringify(this.props.address)
                              : null
                          }
                          size={5}
                          disabled={this.props.disabled}
                          onChange={(e) =>
                            this.props.onChange(
                              JSON.parse(e.target.value),
                              true,
                              false
                            )
                          }
                        >
                          {/* If the query is not fired due to a "skip" prop or is an empty array, display a message */}
                          {options.length === 0 && !this.props.address.city && (
                            <option value="" selected>
                              No Address found
                            </option>
                          )}
                          {options.length === 0 && this.props.address.city && (
                            <option value={JSON.stringify(this.props.address)}>
                              {(window.config.consul.DYCE ||
                              window.DYCE ||
                              (typeof DYCE !== "undefined" ? DYCE : "")
                                ? [
                                    this.props.address.houseNumberAdd,
                                    this.props.address.block,
                                    this.props.address.houseNumber,
                                    this.props.address.street,
                                    this.props.address.dependentStreet,
                                    this.props.address.doubleDependentCity,
                                    this.props.address.dependentCity,
                                    this.props.address.city,
                                    this.props.address.county,
                                    this.props.address.elProfileClass &&
                                      this.props.address.elProfileClass,
                                  ]
                                : [
                                    this.props.address.city,
                                    this.props.address.city,
                                    this.props.address.street,
                                    this.props.address.block,
                                    this.props.address.houseNumber,
                                  ]
                              )
                                .filter((v) => v && v.length > 0)
                                .join(", ")}
                            </option>
                          )}
                          {options.length > 0 &&
                            options.map((option, index) => (
                              <option
                                key={index}
                                value={JSON.stringify(option)}
                              >
                                {(window.config.consul.DYCE ||
                                window.DYCE ||
                                (typeof DYCE !== "undefined" ? DYCE : "")
                                  ? [
                                      option.houseNumberAdd,
                                      option.block,
                                      option.houseNumber,
                                      option.dependentStreet,
                                      option.street,
                                      option.doubleDependentCity,
                                      option.dependentCity,
                                      option.city,
                                      option.county,
                                      option.elProfileClass &&
                                        option.elProfileClass,
                                    ]
                                  : [
                                      option.county,
                                      option.city,
                                      option.street,
                                      option.block,
                                      option.houseNumber,
                                    ]
                                )
                                  .filter((v) => v && v.length > 0)
                                  .join(", ")}
                              </option>
                            ))}
                        </Input>
                      );
                    }}
                  </Query>
                </FormGroup>
              </div>
            )}
            {[
              <p>
                <Input
                  type="radio"
                  name={"newAddress_" + this.props.name}
                  id={"newAddress_" + this.props.name}
                  value={this.state.newAddress}
                  checked={this.state.newAddress}
                  disabled={this.props.disabled}
                  onChange={(e) => {
                    this.setState({ newAddress: true });
                    this.resetAddress();
                    // Cleared the searched and manual values ​​because when we go back to looking up an address
                    // and select a new address button in step 2, we see the top radio button in step 3 with the previously selected address,
                    // but only the input for manual entry of mpan/mprn should be visible.
                    this.props.resetAddressAndIdentifiers();
                  }}
                />
                <label
                  htmlFor={"newAddress_" + this.props.name}
                  className="css-label"
                >
                  <i className="icon ion-android-radio-button-off"></i>
                  <i className="icon ion-android-radio-button-on"></i>
                  &nbsp; &nbsp;or enter a new one:
                </label>
              </p>,
              this.state.newAddress ? (
                <div>
                  {window.config.consul.DYCE ||
                  window.DYCE ||
                  (typeof DYCE !== "undefined" ? DYCE : "") ? (
                    <div>
                      <Row>
                        <Col>{houseNumber}</Col>
                        <Col>{block}</Col>
                      </Row>
                      {street}
                      {dependentStreet}
                      <Row>
                        <Col>{city}</Col>
                        <Col>{county}</Col>
                        <Col>{postalCode}</Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      <Row>
                        <Col>{postalCode}</Col>
                        <Col>{county}</Col>
                        <Col>{city}</Col>
                      </Row>
                      {street}
                      {dependentStreet}
                      <Row>
                        <Col>{block}</Col>
                        <Col>{houseNumber}</Col>
                      </Row>
                    </div>
                  )}
                </div>
              ) : null,
            ]}
          </div>
        );
    }
}

export default FindAddress;
