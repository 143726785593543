import React, { Component } from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import FindAddress from '../FindAddress/FindAddress';
import i18n from "../../views/Pages/Login/i18n";
import {SingleDatePicker} from "react-dates";
import moment from "moment";

class AddressDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const firstName = <Col>
      <FormGroup>
        <Label for={"firstName_"+this.props.name}>First Name:</Label>
        <Input type="text" name={"firstName_"+this.props.name} id={"firstName_"+this.props.name}
               onChange={e => this.props.setContact(this.props.name, {...this.props.contact, firstName: e.target.value})}
               value={this.props.contact.firstName}
               pattern="\s*\S.*"
               placeholder="Enter First Name"  required={this.props.required}/>
      </FormGroup>
    </Col>;
    const lastName = <Col>
      <FormGroup>
        <Label for={"lastName_" + this.props.name}>Last Name:</Label>
        <Input type="text" name={"lastName_" + this.props.name} id={"lastName_" + this.props.name}
               onChange={e => this.props.setContact(this.props.name, {
                 ...this.props.contact,
                 lastName: e.target.value
               })}
               value={this.props.contact.lastName}
               pattern="\s*\S.*"
               placeholder="Enter Last Name" required={this.props.required}/>
      </FormGroup>
    </Col>;
    return (
      <div className="contactDetails">
        <h3>{this.props.title}</h3>
        {this.props.isbilling &&
          <FormGroup tag="fieldset">
            <Input type="checkbox" name={"sameAsPrimary_"+this.props.name} id={"sameAsPrimary_"+this.props.name}
                   checked={this.props.contact.sameAsPrimary}
                   onChange={e => this.props.setContact(this.props.name, {...this.props.contact, sameAsPrimary: e.target.checked})}/>
            <label htmlFor={"sameAsPrimary_"+this.props.name} className="css-label"><i className="icon ion-android-checkbox"/>{i18n.t('contact.sameAsPrimaryContact')}</label>
          </FormGroup>
        }
          {!this.props.contact.sameAsPrimary && <div><Row>
            {(window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' ))
                ? [firstName,lastName]
                : [lastName,firstName]}
          </Row>
            <FormGroup>
              <Label for={"phone_" + this.props.name}>Contact Phone:</Label>
              <Input type="text" name={"phone_" + this.props.name} id={"phone_" + this.props.name}
                     onChange={e => this.props.setContact(this.props.name, {
                       ...this.props.contact,
                       phone: e.target.value
                     })}
                     value={this.props.contact.phone}
                     pattern="\s*\S.*"
                     placeholder="Enter Contact Phone" required={this.props.required}/>
            </FormGroup>
            <FormGroup>
              <Label for={"email_" + this.props.name}>Contact Email:</Label>
              <Input type="email" name={"email_" + this.props.name} id={"email_" + this.props.name}
                     onChange={e => this.props.setContact(this.props.name, {
                       ...this.props.contact,
                       email: e.target.value
                     })}
                     value={this.props.contact.email}
                     placeholder="Enter Contact Email" required={this.props.required}/>
            </FormGroup>
            <FormGroup>
              <Label for="address">Address:</Label>
              <FindAddress name={this.props.name} address={this.props.address}
                           quoteType={this.props.quoteType}
                           onChange={(address) => this.props.setAddress(this.props.name, address)}
                           required={this.props.required}/>
            </FormGroup>
            {this.props.isprimary ?
                <FormGroup>
                  <Label for={"accountPassword_" + this.props.name}>Account Password:</Label>
                  <Input type="text" name={"accountPassword_" + this.props.name}
                         id={"accountPassword_" + this.props.name}
                         onChange={e => this.props.setContact(this.props.name, {
                           ...this.props.contact,
                           accountPassword: e.target.value
                         })}
                         value={this.props.contact.accountPassword}
                         placeholder="Enter Account Password"/>
                </FormGroup>
                : ""}

          </div>}
          {this.props.isbilling && (window.config.consul.DYCE || window.DYCE || (typeof DYCE !== 'undefined' ? DYCE : '' )) &&
            <FormGroup>
              <Label for="billingPreference">Billing:</Label>
              <div>
                <Input 
                  type="checkbox" 
                  name={"billingPreferenceEmail"} 
                  id={"billingPreferenceEmail"}
                  checked={this.props.contact["billingPreferenceEmail"]}
                  onChange={e => this.props.setContact(this.props.name, {...this.props.contact, ["billingPreferenceEmail"]: e.target.checked})}
                />
                  <label htmlFor={"billingPreferenceEmail"} className="css-label"><i className="icon ion-android-checkbox"/>
                    Email Only
                  </label>
              </div>
            </FormGroup>
          }
        {this.props.showDateOfBirth &&
        <FormGroup>
          <Label for={"dateOfBirth_" + this.props.name}>Date of Birth: </Label><br/>
          <SingleDatePicker className={this.state.focusedstartdate ? 'focused' : ''} 
                            date={this.props.contact.dateOfBirth ? moment(this.props.contact.dateOfBirth) : null} // momentPropTypes.momentObj or null
                            numberOfMonths={1}
                            onDateChange={date => {
                              this.props.setContact(this.props.name, {
                                ...this.props.contact,
                                dateOfBirth: date ? date.format("YYYY-MM-DD") : null
                              })
                            }}

                            displayFormat="DD/MM/YY"
                            showDefaultInputIcon={true}
                            inputIconPosition="after"
                            isOutsideRange={day => day.isAfter(moment())}
                            focused={this.state.focusedstartdate} // PropTypes.bool
                            onFocusChange={({ focused }) => this.setState({ focusedstartdate: focused })} // PropTypes.func.isRequired
                            id={"dateOfBirth_" + this.props.name} // PropTypes.string.isRequired,

                            renderMonthElement={({ month, onMonthSelect, onYearSelect }) =>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <div>
                                    <select
                                        value={month.month()}
                                        onChange={(e) => onMonthSelect(month, e.target.value)}
                                    >
                                      {moment.months().map((label, value) => (
                                          <option value={value}>{label}</option>
                                      ))}
                                    </select>
                                  </div>
                                  <div>
                                    <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                                      {new Array(100).fill().map((v, i) => <option value={moment().year()-i}>{moment().year()-i}</option>)}
                                    </select>
                                  </div>
                                </div>}
          />
        </FormGroup>
        }
      </div>
    )
  }
}

export default AddressDetails;
